<template>
  <div class="text-center text-danger">
    <h3>Erro 404 :(</h3>
    <h5>Você não tem permissão para acessar esta página</h5>
    <div class="mt-5 pt-5 text-secondary">
      <router-link :to="`/${first}`">Página Inicial</router-link>
    </div>
  </div>
</template>

<script>
import Menu from "../../Menu"
export default {
  data(){
    return{
      first: Menu[0].link
    }
  }
};
</script>

<style>
</style>